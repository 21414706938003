import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import React, { useState, FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import { API_URL } from "../../api/config";
import Action from "./Action";
import MUIDataTable from "mui-datatables";

export interface ListingStayPageProps {
  className?: string;
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {
  const [currentProp, setCurrentProp] = useState({});
  
  const [propertyData, setPropertyData] = useState<any>([]);

  const getOwnerProperty = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${API_URL}/property/get-owner-property`,
        {
          headers: {
            token: token,
          },
        }
      );
      if (response.data.error === false) {
        const property = response.data.propertydata;

        setPropertyData(property);
      }
      // Set loading to false when data is successfully loaded
    } catch (err) {
      console.error("error while fetching properties data", err);
    }
  };
  console.log("property data", propertyData);
  
  
  const locationPathName = useLocation().pathname;
  useEffect(() => {
    getOwnerProperty();
  }, [locationPathName]);
  const [openDialog, setOpenDailog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDailog(false);
  };

  const deleteProperty = async (currentProp: any) => {
    const token = localStorage.getItem("token");
    console.log("current prop", currentProp);

    try {
      const response = await axios.post(
        `${API_URL}/property/delete-property?propId=${currentProp._id}`,
        {
          headers: {
            token: token,
          },
        }
      );
      const text = response.data.message;
      getOwnerProperty();
      if (response.data.error === false) {
        toast.success(text);
      }
      if (response.data.error === true) {
        toast.error(text);
      }
      handleCloseDialog();
      // Set loading to false when data is successfully loaded
    } catch (err) {
      console.error("error while fetching properties data", err);
      handleCloseDialog();
    }
  };

  const options: any = {
    tableBodyHeight: "60vh",
    filterType: "dropdown",
    selectableRows: "none",
    responsive: "horizontal",
    rowsPerPage: 10,
    page: 0,
    download: false,
    search: false,
    print: false,
    filter: false,
    viewColumns: false,
    customToolbar: () => {
      return <ButtonPrimary href="/add-property">Add Property</ButtonPrimary>;
    },
  };

  const columns = [
    {
      name: "title",
      options: {
        filter: true,
        width: 300,
        customHeadRender: (columnMeta: any) => (
          <th
            key={columnMeta.index}
            style={{
              textAlign: "start",
              paddingLeft: "20px",
              display: columnMeta.display ? "table-cell" : "none",
              position: "sticky",
              top: "0",
              zIndex: "999", // Adjust this value if needed
              backgroundColor: "white", // Optional: adjust if you want a different background color
            }}
          >
            Title
          </th>
        ),

        customBodyRenderLite: (dataIndex: any, rowIndex: any) => (
          <div style={{ textAlign: "start" }}>
            {propertyData[dataIndex].title}{" "}
          </div>
        ),
      },
    },
    {
      name: "address",
      options: {
        filter: true,
        width: 300,
        customHeadRender: (columnMeta: any) => (
          <th
            key={columnMeta.index}
            style={{
              textAlign: "start",
              paddingLeft: "20px",
              display: columnMeta.display ? "table-cell" : "none",
              position: "sticky",
              top: "0",
              zIndex: "999", // Adjust this value if needed
              backgroundColor: "white", // Optional: adjust if you want a different background color
            }}
          >
            Address
          </th>
        ),
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => (
          <div style={{ textAlign: "start" }}>
            {`${
              propertyData[dataIndex].floor
                ? `${propertyData[dataIndex].floor},`
                : ""
            }  ${
              propertyData[dataIndex].area_name
                ? `${propertyData[dataIndex].area_name},`
                : ""
            } ${
              propertyData[dataIndex].landmark
                ? `${propertyData[dataIndex].landmark},`
                : ""
            } ${
              propertyData[dataIndex].city
                ? `${propertyData[dataIndex].city},`
                : ""
            } ${
              propertyData[dataIndex].country
                ? `${propertyData[dataIndex].country}`
                : ""
            } ${" "}`}
          </div>
        ),
      },
    },
    {
      name: "price",
      options: {
        filter: true,
        width: 300,
        customHeadRender: (columnMeta: any) => (
          <th
            key={columnMeta.index}
            style={{
              textAlign: "start",
              paddingLeft: "20px",
              display: columnMeta.display ? "table-cell" : "none",
              position: "sticky",
              top: "0",
              zIndex: "999", // Adjust this value if needed
              backgroundColor: "white", // Optional: adjust if you want a different background color
            }}
          >
            Price
          </th>
        ),

        customBodyRenderLite: (dataIndex: any, rowIndex: any) => (
          <div style={{ textAlign: "start" }}>
            ₹ {propertyData[dataIndex].price}{" "}
          </div>
        ),
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        width: 300,
        customHeadRender: (columnMeta: any) => (
          <th
            key={columnMeta.index}
            style={{
              textAlign: "start",
              paddingLeft: "20px",
              display: columnMeta.display ? "table-cell" : "none",
              position: "sticky",
              top: "0",
              zIndex: "999", // Adjust this value if needed
              backgroundColor: "white", // Optional: adjust if you want a different background color
            }}
          >
            Actions
          </th>
        ),
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => (
          <div style={{ textAlign: "start" }}>
            <Action
              property={propertyData[dataIndex]}
              rowIndex={rowIndex}
              setOpenDialog={setOpenDailog}
              currentProp={setCurrentProp}
            />
          </div>
        ),
      },
    },
  ];

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${
        className || ""
      }`}
      data-nc-id="ListingStayPage"
    >
      <Helmet>
        <title>EZstays-owner || Booking</title>
      </Helmet>
      <div
        className="listingSection__wrap flex items-center justify-center"
        style={{ transform: "scale(0.99)", border: "none" }}
      >
        <div className="lg:container w-full">
          <MUIDataTable
            title={
              <div className="flex justify-between w-full">
                <h2 className="text-2xl font-semibold">Properties</h2>
              </div>
            }
            key={propertyData.length}
            data={propertyData}
            columns={columns}
            options={{
              sort: false, // Disable sorting
              ...options, // Other options you may have
            }}
          />
        </div>
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure, you want to delete this Property?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <ButtonPrimary onClick={() => deleteProperty(currentProp)}>
            Delete
          </ButtonPrimary>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ListingStayPage;
