import Label from "components/Label/Label";
import React, { FC } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";
import  { useState } from "react";

export interface PageAddListing2Props {
  formik: any;
}

const PageAddListing2: FC<PageAddListing2Props> = ({ formik }) => {
  const [selectedType, setSelectedType] = useState("Homestays");

  const {
    country = "",
    area_name = "",
    floor = "",
    landmark = "",
    city = "",
    type = "",
  } = formik.values as any;

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(event.target.value);
    formik.handleChange(event);
  };
  return (
    <CommonLayout
      index="02"
      nextHref="/add-listing-3"
      backtHref="/add-listing-1"
    >
      <>
        {/* <h2 className="text-2xl font-semibold">Your place location</h2> */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
        {/* label="Country/Region" */}
          <FormItem>
            <Select {...formik.getFieldProps("category")} className="!w-3/6">
            <option value="category">category</option>
              <option value="Flat">Flat</option>
              <option value="Bungalow">Bungalow</option>
              <option value="Farm House">Farm House</option>
              <option value="Cottage">Cottage</option>
              {/* <option value="Singapore">Singapore</option>
              <option value="Jappan">Jappan</option>
              <option value="Korea">Korea</option> */}
            </Select>
            {formik.errors && formik.errors.category && (
              <span className="text-red-500 text-sm">
                {formik.errors.category}
              </span>
            )}
          </FormItem>
       

          {/* <FormItem label="Name of the Area">
            <Input placeholder="..." {...formik.getFieldProps("area_name")} />
            {formik.errors && formik.errors.area_name && (
              <span className="text-red-500 text-sm">
                {formik.errors.area_name}
              </span>
            )}
          </FormItem> */}
           <FormItem
          >
            <Select
              {...formik.getFieldProps("type")}
              onChange={handleTypeChange}
              className="!w-3/6"
            >
             <option value="Type of Place">Type of Place</option>

              <option value="Entire Property">Entire Property</option>
              <option value="A Room">A Room</option>

            </Select>
            {formik.errors && formik.errors.type && (
              <span className="text-red-500 text-sm">{formik.errors.type}</span>
            )}
          </FormItem> 

             {/* Address */}
             <FormItem >
              <div className="flex">
              <Input placeholder="Address" {...formik.getFieldProps("Address")} className="w-8/12" />
              <button className="ml-2">Mark on the map</button>
              </div>
           
            {formik.errors && formik.errors.landmark && (
              <span className="text-red-500 text-sm">
                {formik.errors.Address}
              </span>
            )}
          </FormItem>

          {/* Added landmark field */}
          <FormItem label="Nearby landmark">
            <Input placeholder="..." {...formik.getFieldProps("landmark")} />
            {formik.errors && formik.errors.landmark && (
              <span className="text-red-500 text-sm">
                {formik.errors.landmark}
              </span>
            )}
          </FormItem>

            <FormItem label="City">
              <Input {...formik.getFieldProps("city")} />
              {formik.errors && formik.errors.city && (
                <span className="text-red-500 text-sm">
                  {formik.errors.city}
                </span>
              )}
            </FormItem>
            
          <div>
            <Label>Detailed address</Label>
            <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
              {`${floor ? `floor no-${floor},` : ""}  ${
                area_name ? `${area_name},` : ""
              } ${landmark ? `${landmark},` : ""} ${city ? `${city},` : ""} ${
                country ? `${country}` : ""
              } ${" "}`}
            </span>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing2;
