import React, { FC } from "react";
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import LangDropdown from "./LangDropdown";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CurrencyDropdown from "./CurrencyDropdown";
import DropdownTravelers from "./DropdownTravelers";
import { Link, useLocation } from "react-router-dom";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import Abnb from "../abnb/Abnb"
export interface MainNav2Props {
  className?: string;
}

const MainNav2: FC<MainNav2Props> = ({ className = "" }) => {
    const location = useLocation();
    const { pathname } = location;
    const pathsToHide = [
      // "/login",
      // "/signup",
      "/forgot-password",
      // "/verify-otp",
      // "/verify"
    ];
    const shouldHide = pathsToHide.includes(pathname);

    if (shouldHide) {
      return null; // Hide MainNav2 if the current pathname is in the pathsToHide array
    }
  const hasToken = !!localStorage.getItem("token");

  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center border-b border-gray-200">
        <Logo />
        <div className="hidden md:flex justify-center flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10">
          {/* <div className="hidden lg:block  h-10 border-l border-neutral-300 dark:border-neutral-500"></div> */}
          <div className="hidden lg:block">
            <DropdownTravelers />
          </div>
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden items-center lg:flex space-x-1">
            {hasToken ? (
              <AvatarDropdown />
            ) : <Abnb />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2;
