import React, { FC, useEffect, useState, useRef } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";

export interface PageAddListing1Props {
  formik: any;
}

const PageAddListing1: FC<PageAddListing1Props> = ({ formik }) => {
  const [selectedType, setSelectedType] = useState("Homestays");
  const [selectedSubType, setSelectedSubType] = useState("");
  const [otp, setOtp] = useState(Array(4).fill(""));
  const firstInputRef = useRef<HTMLInputElement>(null);


  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(event.target.value);
    formik.handleChange(event);
  };
  const handleSubTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSubType(event.target.value);
    formik.handleChange(event);
  };
  const getDescription = (selectedType: string) => {
    switch (selectedType) {
      case "Homestays":
        return "Homestays: A luxurious residence, often with expansive grounds, offering comfort, privacy, and upscale amenities.";
      default:
        return "A catchy name usually includes: House name + Room name + Featured property + Tourist destination";
    }
  };
  const renderSubtype = (selectedType: string) => {
    switch (selectedType) {
      case "Homestays":
        return ["Entire home unit", "Shared apartments"];
      case "Pgs":
        return ""
      default:
        return [];
    }
  };
  const subtypes = renderSubtype(selectedType);

  useEffect(() => {
    if (subtypes.length > 0) {
      formik.setFieldValue("subtype", subtypes[0]);
    }
  }, []);



  const handleToggleType = (option:any) => {
    formik.setFieldValue("type", option);
    setSelectedType(option)

  };
  const handleToggleSubType = (option:any) => {
    formik.setFieldValue("subtype", option);

  };

  const handleOtpChange = (value: any, index: any) => {
  //   if (!/^\d*$/.test(value)) return;

  //   const newOtp = [...otp];
  //   newOtp[index] = value;
  //   setOtp(newOtp);
    
  // // Update Formik's otp value with the joined OTP digits
  //   validation.setFieldValue("otp", newOtp.join(""));
  //   // Focus the next input
  //   if (value && index < 5) {
  //     document.getElementById(`otp-input-${index + 1}`)?.focus();
  //   }
  };

  return (
    <CommonLayout
      index="01"
      backtHref="/add-listing-1"
      nextHref="/add-listing-2"
    >
      <>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-8">
           
          {/* <FormItem
          >
            <Select
              {...formik.getFieldProps("type")}
              onChange={handleTypeChange}
            >
              <option value="Homestays">Homestays</option>
              <option value="PGs">PGs</option>

            </Select>
            {formik.errors && formik.errors.type && (
              <span className="text-red-500 text-sm">{formik.errors.type}</span>
            )}
          </FormItem>  */}
    
           {/* {subtypes.length > 0 && (
            <FormItem label="Choose a subproperty type">
              <div>
                <select
                  {...formik.getFieldProps("subtype")}
                  onChange={handleSubTypeChange}
                >
                  {subtypes.length > 0 &&
                    subtypes.map((subtype, index) => (
                      <option
                        key={subtype}
                        value={subtype}
                        selected={index === 0 ? true : undefined}
                      >
                        {subtype}
                      </option>
                    ))}
                </select>
                {formik.errors && formik.errors.subtype && (
                  <span className="text-red-500 text-sm">
                    {formik.errors.subtype}
                  </span>
                )}
              </div>
              {formik.errors && formik.errors.type && (
                <span className="text-red-500 text-sm">
                  {formik.errors.type}
                </span>
              )}
            </FormItem>
          )}  */}
        

          <FormItem >
            <Input
            className="!w-3/6 !bg-gray-300 text-black"
              placeholder="Name your property"
              {...formik.getFieldProps("title")}
            />
            {formik.errors && formik.errors.title && (
              <span className="text-red-500 text-sm">
                {formik.errors.title}
              </span>
            )}
          </FormItem>

          <FormItem >
            <div className="flex">
            <Input
            type="tel"
            className="!w-3/6 !bg-gray-300"
              placeholder="Assign phone number"
              {...formik.getFieldProps("propPhoneNumber")}
            />
            <button className="bg-gray-300	px-4 py-2 ml-2">
              Send OTP
            </button>
            </div>
            
            {formik.errors && formik.errors.propPhoneNumber && (
              <span className="text-red-500 text-sm">
                {formik.errors.propPhoneNumber}
              </span>
            )}
          </FormItem>
          <div className="flex space-x-2  mt-2 w-3/6">
                {otp.map((digit, index) => (
                  <Input
                  name="otp"
                    key={index}
                    id={`otp-input-${index}`}
                    type="tel"
                    ref={index === 0 ? firstInputRef : undefined} 
                    className="!w-14 h-14 text-center text-neutral-600 bg-gray-300"
                    value={digit}
                    onChange={(e) => handleOtpChange(e.target.value, index)}
                    maxLength={1}
                  />
                ))}
              </div>
       

          {/* AIR BNB LINK */}
          {/* <FormItem label="Airbnb link">
            <Input
              type="text"
              placeholder=""
              {...formik.getFieldProps("airbnb_link")}
            />
            {formik.errors && formik.errors.airbnb_link && (
              <span className="text-red-500 text-sm">
                {formik.errors.airbnb_link}
              </span>
            )}
          </FormItem> */}
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing1;
